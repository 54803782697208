import { IStateGlobals, EnumsIcons, EnumsSecondaryIcons, EnumFilterDrawerIcons } from './types';
import localStorage from '@/services/localStorage';

const lsProcesses = localStorage.getItem('processes');
const processes = lsProcesses ? JSON.parse(lsProcesses) : [];

const state: IStateGlobals = {
  title: '',
  alertTitle: '',
  alertText: '',
  alertTextDynamic: '',
  alertDialogIsOpen: false,
  alertTwoButtons: true,
  loader: false,
  token: localStorage.getItem('token') || '',
  processes,
  icon: EnumsIcons.noImage,
  secondaryIcon: EnumsSecondaryIcons.noIcon,
  filterDrawerIcon: EnumFilterDrawerIcons.noIcon,
  filterDrawer: false,
  searchBarInput: '',
  searchBy: '',
  previousMainPage: '',
  userEmail: localStorage.getItem('email'),
  previousPage: {
    name: '',
    params: {},
  },
  action: 0,
  boxShippingData: [],
  params: {
    page: 1,
  },
  file: null,
  boxStates: [
    // reBOX is fine
    {
      stateIsTrue: false,
    },
    // reBOX is damaged
    {
      stateIsTrue: false,
    },
  ],
  isBoxRegistrationComponent: false,
  registeredBoxes: [],
  addProviderError: false,
};

export default state;
