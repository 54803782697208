import { IBoxInventoryTemplate } from '@/services/Repositories/boxInventoryTemplate/types';

class LocalStorage {
  prefix = 'RBX';

  localeName = 'locale';

  setItem(name: string, value: string): void {
    localStorage.setItem(`${this.prefix}_${name}`, value);
  }

  getItem(name: string): string | null {
    return localStorage.getItem(`${this.prefix}_${name}`);
  }

  removeItem(name: string): void {
    localStorage.removeItem(`${this.prefix}_${name}`);
  }

  setLocale(value: string) {
    this.setItem(this.localeName, value);
  }

  getLocale(): string {
    return localStorage.getItem(this.localeName) || process.env.VUE_APP_I18N_LOCALE;
  }
}

export default new LocalStorage();
