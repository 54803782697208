/* eslint-disable class-methods-use-this */
import jwtDecode from 'jwt-decode';

interface IToken {
  exp: number;
  lat: number;
  roles: Array<string>;
}

interface ITokenService {
  isTokenExpired: (token: string) => boolean;
  userRoles: (token: string) => string[];
}

class TokenService implements ITokenService {
  isTokenExpired(token: string): boolean {
    const todayDate = new Date().getTime();
    const { exp } = jwtDecode<IToken>(token);
    if (exp < todayDate / 1000) {
      return true;
    }
    return false;
  }

  userRoles(token: string): string[] {
    const { roles } = jwtDecode<IToken>(token);
    return roles;
  }
}

export default TokenService;
