/* eslint-disable import/no-cycle */
import { actionTree } from 'typed-vuex';
import initialGetters from './getter';
import initialState from './state';
import mutations from './mutations';
import boxesRepository from '@/services/Repositories/Boxes/BoxesRepository';

const actions = actionTree(
  { state: initialState, getters: initialGetters, mutations },
  {
    async getBoxes({ commit }) {
      const query = { ...initialState.boxesQuery };
      delete query.sortBy;
      const { data } = await boxesRepository.get(query);
      const boxesAmount = data['hydra:totalItems'];
      const boxes = data['hydra:member'];
      const processedBoxes = boxes.map((box: any) => ({
        ...box,
        customer: box.currentBoxShipping
          ? `${box.currentBoxShipping.order.custName} ${box.currentBoxShipping.order.custSurname}`
          : '',
        customerAddress: box.currentBoxShipping
          ? `${box.currentBoxShipping.order.delAddressStreet} ${box.currentBoxShipping.order.delAddressStreetNr} ${box.currentBoxShipping.order.delAddressZip} ${box.currentBoxShipping.order.delAddressCity}`
          : '',
      }));
      commit('ADD_BOXES', processedBoxes);
      commit('SET_BOXES_AMOUNT', boxesAmount);
      initialState.boxes.forEach(async ({ updatedAt, ...rest }: any, index) => {
        const daysSinceChange = new Date(updatedAt).getTime();
        const updatedObj = {
          ...rest,
          updatedAt,
          daysSinceChange,
        };
        commit('UPDATE_BOX', { index, box: updatedObj });
      });
    },
  },
);

export default actions;
