import { actionTree } from 'typed-vuex';
import initialGetters from './getter';
import initialState from './state';
import mutations from './mutations';

import { IOrder } from '@/services/Repositories/OrdersRepository/types';

const actions = actionTree(
  { state: initialState, getters: initialGetters, mutations },
  {
    addOrders(
      { commit, getters },
      { orders, totalItems }: { orders: IOrder[]; totalItems: number },
    ) {
      const { orders: orderState } = initialState;
      if (orderState.length === 0) {
        commit('SET_ORDERS', orders);
        if (orderState.length % 30 === 0) {
          commit('NEXT_PAGE');
        }
        return;
      }
      if (orderState.length <= totalItems) {
        orders.forEach((order) => {
          const index = getters.getOrderIndex(order.id);
          if (index >= 0) {
            commit('UPDATE_ORDER', { index, payload: order });
          }
        });
        // eslint-disable-next-line arrow-body-style
        const uniqueOrders = orders.filter((obj) => {
          return !orderState.find((obj2) => obj.id === obj2.id);
        });
        commit('SET_ORDERS', uniqueOrders);
      }
      if (orderState.length < totalItems && orderState.length % 30 === 0) {
        commit('NEXT_PAGE');
      }
    },
    addOrder({ commit, getters }, payload: IOrder) {
      const index = getters.getOrderIndex(payload.id);
      if (index >= 0) {
        commit('UPDATE_ORDER', { index, payload });
      } else {
        commit('ADD_ORDER', payload);
      }
    },
  },
);

export default actions;
