import { IStateOrders, EnumFilterStatus } from './types';

const state: IStateOrders = {
  orders: [],
  params: {
    page: 1,
    filterBy: {},
    shipStatus: EnumFilterStatus.NOT_SHIPPED,
  },
  searchCard: false,
  deleteMode: false,
  deleteIcon: false,
};

export default state;
