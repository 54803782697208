import { Module } from 'vuex';
import { IRootState } from '@/store/types';
import { IStateReboxes } from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getter';
import state from './state';

const orders: Module<IStateReboxes, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default orders;
