





























import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import store from '@/store';
import { EnumsActions } from '@/store/modules/globals/types';

export default Vue.extend({
  name: 'BaseAlert',
  computed: {
    ...mapState('globals', [
      'alertTitle',
      'alertText',
      'alertTextDynamic',
      'alertTwoButtons',
      'alertDialogIsOpen',
      'action',
      'previousMainPage',
    ]),
  },
  methods: {
    ...mapMutations('globals', ['SET_ALERT_DIALOG', 'SET_ALERT_TITLE', 'SET_ALERT_TEXT', 'SET_ALERT_TEXT_DYNAMIC', 'SET_ALERT_TWO_BUTTONS', 'SET_LOADER', 'SET_ACTION']),
    clearTexts(): void {
      this.SET_ALERT_TITLE('');
      this.SET_ALERT_TEXT('');
      this.SET_ALERT_TEXT_DYNAMIC('');
    },
    handleClick(): void {
      if (this.action === 4) {
        this.SET_ALERT_DIALOG();
        this.SET_LOADER(false);
        if (this.$route.name === 'Provider') {
          store.commit('globals/SET_ACTION', EnumsActions.close);
          return;
        }
        if (this.$route.name === 'AddShipmentProvider') {
          store.commit('globals/SET_ACTION', EnumsActions.goBack);
          return;
        }
      } else if (this.action === 3) {
        this.$router.replace({ name: this.previousMainPage });
        this.SET_ALERT_DIALOG();
      } else {
        this.SET_ALERT_DIALOG();
      }
      this.clearTexts();
      this.SET_ALERT_TWO_BUTTONS(true);
    },
  },
});
