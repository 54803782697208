import { mutationTree } from 'typed-vuex';
import initialState from './state';
import localStorage from '@/services/localStorage';
import { EnumsActions, EnumsIcons, EnumsSecondaryIcons, EnumFilterDrawerIcons } from './types';
import { Process } from '@/utils/availableProcesses';

const mutations = mutationTree(initialState, {
  SET_TITLE(state, payload: string) {
    state.title = payload;
  },
  SET_ALERT_DIALOG(state) {
    state.alertDialogIsOpen = !state.alertDialogIsOpen;
  },
  SET_ALERT_TITLE(state, payload: string) {
    state.alertTitle = payload;
  },
  SET_ALERT_TEXT(state, payload: string) {
    state.alertText = payload;
  },
  SET_ALERT_TEXT_DYNAMIC(state, payload: string) {
    state.alertTextDynamic = payload;
  },
  SET_ALERT_TWO_BUTTONS(state, payload: boolean) {
    state.alertTwoButtons = payload;
  },
  SET_LOADER(state, payload: boolean) {
    state.loader = payload;
  },
  SET_TOKEN(state, payload: string) {
    localStorage.setItem('token', payload);
    state.token = payload;
  },
  SET_PROCESSES(state, payload: Array<Process>) {
    localStorage.setItem('processes', JSON.stringify(payload));
    state.processes = payload;
  },
  REMOVE_TOKEN(state) {
    state.token = '';
  },
  SET_BOX_SHIPPING_DATA(state, payload) {
    state.boxShippingData = payload;
  },
  LOGOUT(state) {
    state.token = '';
    state.processes = [];
    localStorage.removeItem('token');
    localStorage.removeItem('processes');
  },
  SET_ICON(state, payload: EnumsIcons) {
    state.icon = payload;
  },
  SET_ACTION(state, payload: EnumsActions) {
    state.action = payload;
  },
  SET_PAGE(state, payload: string) {
    state.previousMainPage = payload;
  },
  SET_SECONDARY_iCON(state, payload: EnumsSecondaryIcons) {
    state.secondaryIcon = payload;
  },
  SET_SEARCH_INPUT(state, payload: string) {
    state.searchBarInput = payload;
  },
  SET_FILE(state, payload: File | null) {
    state.file = payload;
  },
  SET_USER_EMAIL(state, payload: string) {
    state.userEmail = payload;
  },
  SET_FILTERDRAWER_ICON(state, payload: EnumFilterDrawerIcons) {
    state.filterDrawerIcon = payload;
  },
  SET_FILTERDRAWER(state, payload: boolean) {
    state.filterDrawer = payload;
  },
  SET_PREVIOUS_PAGE(state, payload: { name: string; params?: any }) {
    state.previousPage = payload;
  },
  SET_SEARCHBY(state, payload: string) {
    state.searchBy = payload;
  },
  SET_BOXSTATE_FINE(state, payload: boolean) {
    state.boxStates[0].stateIsTrue = payload;
  },
  SET_BOXSTATE_DAMAGED(state, payload: boolean) {
    state.boxStates[1].stateIsTrue = payload;
  },
  CLEAR_BOXSTATES(state) {
    state.boxStates.forEach((boxState: any) => {
      boxState.stateIsTrue = false; // eslint-disable-line
    });
  },
  SET_ISBOXREGISTRATIONCOMPONENT(state, payload: boolean) {
    state.isBoxRegistrationComponent = payload;
  },
  SET_REGISTER_BOXES(state, payload: string) {
    state.registeredBoxes.unshift(payload);
  },
  SET_ADD_PROVIDER_ERROR(state, payload: boolean) {
    state.addProviderError = payload;
  },
});

export default mutations;
