import { getterTree } from 'typed-vuex';
import initialState from './state';

const getters = getterTree(initialState, {
  getOrder: (state) => (id: string) => state.orders.find((order) => order.id === id),
  getOrderIndex: (state) => (id: string) => state.orders.findIndex((order) => order.id === id),
  getFilterby: ({ params }) => params.filterBy,
});

export default getters;
