import Vue from 'vue';
import { mutationTree } from 'typed-vuex';
import initialState from './state';
import { IOrder } from '@/services/Repositories/OrdersRepository/types';
import { EnumFilterStatus } from './types';

const mutations = mutationTree(initialState, {
  SET_ORDERS(state, payload: IOrder[]) {
    state.orders.push(...payload);
  },
  ADD_ORDER(state, payload: IOrder) {
    state.orders.push(payload);
  },
  UPDATE_ORDER(state, { index, payload }: { index: number; payload: IOrder }) {
    Vue.set(state.orders, index, payload);
  },
  CLEAR_ORDERS(state) {
    state.orders = [];
  },
  SET_PAGE(state, payload = 1) {
    state.params.page = payload;
  },
  NEXT_PAGE(state) {
    state.params.page = state.params.page ? state.params.page + 1 : 1;
  },
  CLEAR_PAGE(state) {
    state.params.page = 1;
  },
  SET_STATUS_FILTER(state, payload: EnumFilterStatus) {
    state.params.shipStatus = payload;
  },
  SET_FILTERBY(state, payload: { [index: string]: string }) {
    state.params.filterBy = payload;
  },
  SET_SEARCHCARD(state, payload: boolean) {
    state.searchCard = payload;
  },
  SET_DELETE_MODE(state, payload: boolean) {
    state.deleteMode = payload;
  },
  SET_DELETE_ICON(state, payload: boolean) {
    state.deleteIcon = payload;
  },
});

export default mutations;
