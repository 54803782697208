import { actionTree } from 'typed-vuex';
import initialGetters from './getter';
import initialState from './state';
import mutations from './mutations';

const actions = actionTree(
  { state: initialState, getters: initialGetters, mutations },
  {
    /// OUR ACTIONS
    setBoxShippingData({ commit }, boxShippingData) {
      commit('SET_BOX_SHIPPING_DATA', boxShippingData);
    },
  },
);

export default actions;
