import { mutationTree } from 'typed-vuex';
import initialState from './state';

const mutations = mutationTree(initialState, {
  ADD_EDITED_ORDER(state, payload: string) {
    state.editedOrders.unshift(payload);
  },
  ADD_EDITED_BOX(state, payload: string) {
    state.editedBoxes.unshift(payload);
  },
  CLEAR_EDITED_ORDERS(state) {
    state.editedOrders = [];
  },
  CLEAR_EDITED_BOXES(state) {
    state.editedBoxes = [];
  },
});

export default mutations;
