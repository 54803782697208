import VueI18n from '@/i18n';
import quotesValidator from './quotesInFile';
import semicolonValidatorValidator from './semicolonSeperator';
// import checkDateFromat from './checkDateFormat';

interface FileValidatorReturn {
  errors: string[];
  validator: (file: string) => void;
  formMessage: () => string;
}

const FileValidator = (): FileValidatorReturn => {
  const errors: string[] = [];
  const functions = [
    {
      fn: quotesValidator,
      message: () => VueI18n.t('error-message.no-quotes-allowed').toString(),
    },
    {
      fn: semicolonValidatorValidator,
      message: () => VueI18n.t('error-message.semicolon-required').toString(),
    },
    // {
    //   fn: checkDateFromat,
    //   message: () => VueI18n.t('error-message.wrong-date-format').toString(),
    // },
  ];

  const validator = (file: string) => {
    functions.forEach(({ fn, message }) => {
      if (fn(file)) {
        return;
      }
      errors.push(message());
    });
  };

  const formMessage = () => errors.join('\n');

  return { errors, validator, formMessage };
};

export default FileValidator;
