/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import TokenService from '@/services/TokenService';

const tokenService = new TokenService();

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: { checkAuth: true },
  },
  {
    path: '/orders',
    name: 'order',
    component: () => import('@/views/orders/index.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '/',
        name: 'allOrders',
        component: () => import('@/views/orders/index/index.vue'),
      },
      {
        path: ':orderId',
        name: 'orderDetails',
        component: () => import('@/views/orders/index/id.vue'),
      },
      {
        path: ':orderId/reboxes',
        name: 'orderRebxoes',
        component: () => import('@/views/orders/index/id/Reboxes.vue'),
      },
      {
        path: ':orderId/labels',
        name: 'orderLabels',
        component: () => import('@/views/orders/index/id/Labels.vue'),
      },
      {
        path: ':orderId/create',
        name: 'findRebox',
        component: () => import('@/views/orders/index/id/Create.vue'),
      },
      {
        path: ':orderId/create/:reboxId',
        name: 'createRebox',
        component: () => import('@/views/orders/index/id/create/reboxId.vue'),
      },
    ],
  },
  {
    path: '/returns',
    name: 'returns',
    component: () => import('@/views/returns/index.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '/',
        name: 'ReboxScanner',
        component: () => import('@/views/returns/index/index.vue'),
      },
      {
        path: ':reboxId',
        name: 'ReboxReturn',
        component: () => import('@/views/returns/index/reboxId.vue'),
      },
      {
        path: ':reboxId/note',
        name: 'ReboxNote',
        props: true,
        component: () => import('@/views/returns/index/reboxid/note.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/box-overview',
    name: 'BoxOverview',
    component: () => import('@/views/boxes/BoxOverview.vue'),
    meta: { checkAuth: true },
  },
  {
    path: '/boxes/:code',
    name: 'BoxDetail',
    component: () => import('@/views/boxes/BoxDetail.vue'),
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('@/views/configuration/index.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '/',
        name: 'configurationIndex',
        component: () => import('@/views/configuration/index/index.vue'),
      },
      {
        path: 'inventory-templates',
        name: 'InventoryTemplates',
        component: () => import('@/views/configuration/InventoryTemplates.vue'),
      },
      {
        path: 'shipping-provider',
        name: 'ShippingProvider',
        component: () => import('../views/configuration/ShippingProvider.vue'),
      },
      {
        path: 'shipping-provider/add-provider',
        name: 'AddShipmentProvider',
        component: () => import('@/views/configuration/ShippingProvider/AddShipmentProvider.vue'),
      },
      {
        path: 'shipping-provider/:providerId',
        name: 'Provider',
        component: () => import('@/views/configuration/ShippingProvider/id.vue'),
      },
      {
        path: 'create-inventory-template',
        name: 'CreateInventoryTemplate',
        component: () => import('@/views/configuration/NewInventoryTemplate.vue'),
      },
      {
        path: ':id',
        name: 'EditInventoryTemplate',
        component: () => import('@/views/configuration/NewInventoryTemplate.vue'),
      },
    ],
  },
  {
    path: '/orders-import',
    name: 'Import',
    component: () => import('@/views/ordersImport/index.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '/',
        name: 'OrdersImport',
        component: () => import('@/views/ordersImport/index/index.vue'),
      },
      {
        path: 'order',
        name: 'ImportedOrder',
        component: () => import('@/views/ordersImport/index/Order.vue'),
      },
    ],
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: () => import('@/views/userManagement/UserManagement.vue'),
  },
  {
    path: '/registration-form/:invitationCode',
    name: 'RegistrationForm',
    component: () => import('@/views/RegistrationForm.vue'),
  },
  {
    path: '/registration',
    name: 'BoxRegistration',
    component: () => import('@/views/boxes/BoxRegistration.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  store.commit('globals/SET_FILTERDRAWER', false);
  if (to.matched.some((record) => record.meta.checkAuth)) {
    const { path } = to;
    const { processes } = store.state.globals;
    const correctPath = processes.find(({ routePath }) => path.includes(routePath));
    if (
      // eslint-disable-next-line operator-linebreak
      store.state.globals.token === '' ||
      tokenService.isTokenExpired(store.state.globals.token)
    ) {
      store.commit('globals/LOGOUT');
      next('/login');
    } else if (path !== '/' && !correctPath) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
