import Vue from 'vue';
import { mutationTree } from 'typed-vuex';
import initialState from './state';
import { IBoxesQueries } from './types';

const mutations = mutationTree(initialState, {
  SET_BOXES_QUERIES(state, payload: IBoxesQueries) {
    state.boxesQuery = payload;
  },
  SET_BOXES_AMOUNT(state, payload: number) {
    state.boxesAmount = payload;
  },
  ADD_BOXES(state, payload: any) {
    state.boxes = payload;
  },
  UPDATE_BOX(state, { index, box }: { index: number; box: any }) {
    Vue.set(state.boxes, index, box);
  },
  SET_BOXES_MERCHANT(state, payload: string) {
    state.boxesQuery.merchant = payload;
  },
  SET_QUERY_BASED_ON_INPUT(state, { key, value }: { key: string, value: string}) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.boxesQuery[key] = value;
  },
  CLEAR_QUERY(state) {
    state.boxesQuery = {
      page: 1,
      itemsPerPage: 30,
      status: 0,
      sortBy: [],
      merchant: '',
      code: '',
      'boxShippings.order.orderNr': '',
      'boxShippings.order.custEmail': '',
      'boxShippings.order.delAddressZip': '',
      'boxShippings.order.delAddressCity': '',
      'boxShippings.order.delAddressStreet': '',
      'boxShippings.order.delAddressStreetNr': '',
    };
  },
});

export default mutations;
