// import MockAxios from 'axios-mock-adapter';
import { AxiosResponse } from 'axios';
import axios from '@/services/Repository';
import IBoxesRepository, { IRegisterBox, IReturnBox } from './types';
import queryBuilder from '@/utils/queryBuilder';
// import patchBoxShip from '@/api/mocks/postShipBox';

// const mock = new MockAxios(axios, { onNoMatch: 'passthrough' });

// const getPatchRegex = new RegExp('boxes/*');

// mock.onPatch(getPatchRegex).reply(201, patchBoxShip);

const boxesRepository: IBoxesRepository = {
  resource: 'boxes',

  async get(query) {
    const data = await axios.get(this.resource, { params: queryBuilder(query) });
    return data;
  },

  async getOne(code: string) {
    const data = await axios.get(`${this.resource}/${code}`);
    return data;
  },

  async shipBox(boxCode, body) {
    const path = `${this.resource}/${boxCode}/ship`;
    const data = await axios.patch(path, { boxShipping: body });
    return data;
  },

  async registerBox(boxCode, body) {
    const path = `${this.resource}/${boxCode}/register`;
    const data: AxiosResponse<IRegisterBox> = await axios.put(path, body);
    return data;
  },

  async returnBox(boxCode, body) {
    const path = `${this.resource}/${boxCode}/return`;
    const data: AxiosResponse<IReturnBox> = await axios.patch(path, body);
    return data;
  },

  async returnDamagedBox(boxCode, body) {
    const path = `${this.resource}/${boxCode}/return_damaged`;
    const data: AxiosResponse<IReturnBox> = await axios.patch(path, body);
    return data;
  },

  async changeBoxStatusToDeliveredToMerchant(boxCode) {
    const path = `${this.resource}/${boxCode}/return_finalizing`;
    const data = await axios.patch(path);
    return data;
  },
  async getStatusCount() {
    const data = await axios.get(`${this.resource}/count_by_status`);
    return data;
  },
};

export default boxesRepository;
