export interface IQuery {
  status?: number | string;
  sortBy?: string[];
}

export interface IObjectKeys extends IQuery {
  [key: string]: string | number | undefined | string[];
}

function queryBuilder(params: any): IQuery {
  const param: IObjectKeys = {};
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (key === 'filterBy') {
      const nKey = Object.keys(value)[0];
      const nValue = Object.values<string>(value)[0];

      if (nValue) {
        param[nKey] = nValue;
      }
    } else if (value) {
      param[key] = value;
    }
  });
  return param;
}

export default queryBuilder;
