import { getterTree } from 'typed-vuex';
import initialState from './state';

const getters = getterTree(initialState, {
  // OUR GETTERS
  // example:
  // get: state => (id: string) => state.smth.find(sth => sth.id === id)
});

export default getters;
