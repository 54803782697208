/* eslint-disable class-methods-use-this */
import store from '@/store';
import {
  EnumsActions,
  EnumsIcons,
  EnumsSecondaryIcons,
  EnumFilterDrawerIcons,
} from '@/store/modules/globals/types';

interface IIconsConfig {
  icon: EnumsIcons;
  action: EnumsActions;
}

interface IPageConfig {
  name: string;
  params?: { [index: string]: string };
}

interface IConfig {
  title?: string;
  icons?: IIconsConfig;
  page?: IPageConfig;
  secondaryIcon?: EnumsSecondaryIcons;
  drawerIcon?: EnumFilterDrawerIcons;
}

export default class HeaderService {
  store = store;

  getIconsAndActions() {
    return {
      icons: EnumsIcons,
      actions: EnumsActions,
      secondaryIcons: EnumsSecondaryIcons,
      filterIcons: EnumFilterDrawerIcons,
    };
  }

  setHeaderTitle(title: string): void {
    this.store.commit('globals/SET_TITLE', title);
  }

  setHeaderIcons(iconsConfig: IIconsConfig): void {
    this.store.commit('globals/SET_ICON', iconsConfig.icon);
    this.store.commit('globals/SET_ACTION', iconsConfig.action);
  }

  setPreviousPage(pageConfig: IPageConfig): void {
    this.store.commit('globals/SET_PREVIOUS_PAGE', pageConfig);
  }

  setSecondaryIcon(icon: EnumsSecondaryIcons): void {
    this.store.commit('globals/SET_SECONDARY_iCON', icon);
  }

  setDrawerIcon(icon: EnumFilterDrawerIcons): void {
    this.store.commit('globals/SET_FILTERDRAWER_ICON', icon);
  }

  config(config: IConfig): void {
    const functions = {
      title: (title: string) => this.setHeaderTitle(title),
      icons: (icons: IIconsConfig) => this.setHeaderIcons(icons),
      page: (page: IPageConfig) => this.setPreviousPage(page),
      secondaryIcon: (icon: EnumsSecondaryIcons) => this.setSecondaryIcon(icon),
      drawerIcon: (icon: EnumFilterDrawerIcons) => this.setDrawerIcon(icon),
    };
    Object.entries(config).forEach((key) => {
      Object.entries(functions).forEach((_key) => {
        if (_key[0] === key[0]) {
          const fn: any = _key[1];
          fn(key[1]);
        }
      });
    });
  }
}
