/* eslint-disable import/no-cycle */
import { Module } from 'vuex';
import { IRootState } from '@/store/types';
import IBoxesState from './types';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';
import getters from './getter';
import state from './state';

/* instead of Module<any, any> in feature
 * we have to specify it as Module<ModuleState, RootState>
 * ModuleState - TypeScript interface dedicated for ONE module
 *   in our example it will be dedicated for module named globals
 * RootState - TypeScript itnerface with contains state of all modues
 */

const globals: Module<IBoxesState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default globals;
