













import UpdateMixin from '@/mixins/UpdateMixin';

export default UpdateMixin.extend({
  name: 'UpdateAlert',
});
