import IBoxes from './types';

const state: IBoxes = {
  boxes: [],
  boxesQuery: {
    page: 1,
    itemsPerPage: 30,
    status: 0,
    sortBy: [],
    merchant: '',
    code: '',
    'boxShippings.order.orderNr': '',
    'boxShippings.order.custEmail': '',
    'boxShippings.order.delAddressZip': '',
    'boxShippings.order.delAddressCity': '',
    'boxShippings.order.delAddressStreet': '',
    'boxShippings.order.delAddressStreetNr': '',
  },
  boxesAmount: 0,
};

export default state;
