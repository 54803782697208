/* eslint-disable class-methods-use-this */
import { AxiosInstance } from 'axios';
import TokenService from './TokenService';
import ErrorHandler from './ErrorHandler';
import LsService from './localStorage';
import store from '../store';
import router from '@/router';
import { EnumsActions } from '@/store/modules/globals/types';
import errorSound from '@/assets/sounds/errorSound.mp3';

const tokenService = new TokenService();
const REBOX_INFORMATION_SITE = 'https://re-box.io/rueckversand/';
const audioErrorSound = new Audio(errorSound);

export default class AxiosInterceptor {
  setAxiosInterceptor(Axios: AxiosInstance): void {
    Axios.interceptors.request.use(
      (config) => {
        const { token } = store.state.globals;
        const { method, url } = config;
        try {
          if (token) {
            this.logoutIfTokenTimeout(token);
            // eslint-disable-next-line no-param-reassign
            config.headers = {
              common: {
                // eslint-disable-next-line quote-props
                Authorization: `Bearer ${token}`,
              },
            };
          }
          if (url && !url.includes('login') && !token && url.includes('registration-form')) {
            router.replace({ name: 'login' });
          }

          if (method === 'patch') {
            // eslint-disable-next-line no-param-reassign
            config.headers = {
              ...config.headers,
              'Content-Type': 'application/merge-patch+json',
            };
          }
          store.commit('globals/SET_LOADER', true);
        } catch (e) {
          return e;
        }
        return config;
      },
      (error) => {
        if (((error || {}).config || {}).url) {
          ErrorHandler.handler(error);
          store.commit('globals/SET_LOADER', false);
        }
        return Promise.reject(error);
      },
    );

    Axios.interceptors.response.use(
      (response) => {
        store.commit('globals/SET_LOADER', false);
        return response;
      },
      (error) => {
        const { token } = store.state.globals;
        const code = error.request.responseURL.split('/').pop();
        const boxCode = error.request.responseURL.split('/').reverse()[1];
        const apiURL = process.env.VUE_APP_BASE_URL;
        if (error.request.responseURL === `${apiURL}invitations/${code}`) {
          router.push('/login');
          store.commit('globals/SET_ALERT_TITLE', 'errors.invitation-outdated-title');
          store.commit('globals/SET_ALERT_TEXT', 'errors.invitation-outdated-text');
          store.commit('globals/SET_ALERT_DIALOG', true);
          store.commit('globals/SET_ALERT_TWO_BUTTONS', false);
          return Promise.reject(error);
        }
        if (!token && error.request.responseURL === `${apiURL}boxes/${code}`) {
          window.location.href = REBOX_INFORMATION_SITE;
          return Promise.reject(error);
        }
        if (error.request.responseURL === `${apiURL}boxes/${code}`) {
          router.push('/box-overview');
          store.commit('globals/SET_ALERT_TITLE', 'errors.box-details-no-permission-title');
          store.commit('globals/SET_ALERT_TEXT', 'errors.box-details-no-permission-text');
          store.commit('globals/SET_ALERT_DIALOG', true);
          store.commit('globals/SET_ALERT_TWO_BUTTONS', false);
          return Promise.reject(error);
        }
        if (error.request.responseURL === `${apiURL}boxes/${boxCode}/ship`) {
          const { data } = error.response;
          store.commit('globals/SET_ALERT_TITLE', 'errors.dpd-error-title');
          store.commit('globals/SET_ALERT_TEXT', 'errors.dpd-error-text');
          store.commit('globals/SET_ALERT_TEXT_DYNAMIC', data['hydra:description']);
          store.commit('globals/SET_ACTION', EnumsActions.closeDialog);
          store.commit('globals/SET_ALERT_DIALOG', true);
          store.commit('globals/SET_ALERT_TWO_BUTTONS', false);
          audioErrorSound.play();
          return Promise.reject(error);
        }
        if (error.request.responseURL === `${apiURL}shipment_provider_configs/${code}`) {
          store.commit('globals/SET_ALERT_TITLE', 'errors.delete-provider-failed-title');
          store.commit('globals/SET_ALERT_TEXT', 'errors.delete-provider-failed-text');
          store.commit('globals/SET_ACTION', EnumsActions.closeDialog);
          store.commit('globals/SET_ALERT_DIALOG', true);
          store.commit('globals/SET_ALERT_TWO_BUTTONS', false);
          return Promise.reject(error);
        }
        if (((error || {}).config || {}).url) {
          ErrorHandler.handler(error);
          store.commit('globals/SET_LOADER', false);
        }
        return Promise.reject(error);
      },
    );
  }

  logoutIfTokenTimeout(token: string): void {
    const isExpired = tokenService.isTokenExpired(token);
    if (isExpired) {
      LsService.removeItem(token);
      store.commit('globals/LOGOUT');
      store.commit('orders/CLEAR_ORDERS');
    }
  }
}
