import { extend } from 'vee-validate';
import { required, email, min, ext, length } from 'vee-validate/dist/rules';
import VueI18n from '../i18n';
import FileValidator from '@/utils/filesUtils/fileValidator';

// import semicolonSeperator from '@/utils/filesUtils/semicolonSeperator';
// import quotesInFile from '@/utils/filesUtils/quotesInFile';

extend('required', {
  ...required,
  message: (_, values) => VueI18n.t('error-message.required', values).toString(),
});

extend('email', {
  ...email,
  message: (_, values) => VueI18n.t('error-message.email', values).toString(),
});

extend('min', {
  ...min,
  message: (_, values) => VueI18n.t('error-message.password', values).toString(),
});

extend('length', {
  ...length,
  message: (_, values) => VueI18n.t('error-message.rebox-id-length', values).toString(),
});

extend('password', {
  params: ['target'],
  validate(value, { target }: any) {
    return value === target;
  },
  message: (_, values) => VueI18n.t('error-message.password-confirmation', values).toString(),
});

extend('ext', {
  ...ext,
  message: (_, value) => VueI18n.t('error-message.wrong-file-type', value).toString(),
});

extend('fileValidation', async (value) => {
  const reader = new FileReader();
  const { errors, validator, formMessage } = FileValidator();
  reader.readAsText(value);
  const validate = await new Promise((resolve) => {
    reader.onload = (event) => {
      if (event.target) {
        const { target } = event;
        if (typeof target.result === 'string') {
          validator(target.result);
          return resolve(errors.length === 0);
        }
      }
      return resolve(false);
    };
  });
  return validate ? true : formMessage();
});
