import { IOrder } from '@/services/Repositories/OrdersRepository/types';

// eslint-disable-next-line no-shadow
export enum EnumFilterStatus {
  NOT_SHIPPED = 'NOT_SHIPPED',
  SHIPPED = 'SHIPPED',
}

// export interface IParams {
//   page: number;
//   shipStatus: EnumFilterStatus;
// }

interface IParams {
  page: number;
  filterBy: {
    [index: string]: string;
  };
  shipStatus: EnumFilterStatus;
}

export interface IStateOrders {
  orders: Array<IOrder>;
  params: IParams;
  searchCard: boolean;
  deleteMode: boolean;
  deleteIcon: boolean;
}
