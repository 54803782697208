import { AxiosError } from 'axios';
import store from '@/store';
import errorSound from '@/assets/sounds/errorSound.mp3';

const ErrorHandler = {
  handler(error: AxiosError): void {
    if (error.response) {
      const ctxRequestURL = error.request.responseURL;
      const { status, data } = error.response;
      const apiMessage = data['hydra:description'] || data.message;
      const { errors } = data;
      const message = this.createErrorMessage(status, apiMessage, errors, ctxRequestURL);
      const audio = new Audio(errorSound);
      store.commit('globals/SET_ALERT_TITLE', 'errors.title');
      store.commit('globals/SET_ALERT_TEXT', message);
      store.commit('globals/SET_ALERT_DIALOG');
      store.commit('globals/SET_ALERT_TWO_BUTTONS', false);
      audio.play();
    }
  },

  createErrorMessage(status: number, message: string, errors: any, ctxRequestURL: string): string {
    const baseAPIURL = process.env.VUE_APP_BASE_URL;
    const regex = /^boxes\/[ A-Za-z0-9_@./#&+-]*$/;
    const substractedReqURL = ctxRequestURL.replace(baseAPIURL, ''); // ToDo find better way?
    // expecting Array with one element which means that pattern is matched
    const matched = substractedReqURL.match(regex);
    switch (status) {
      case 0:
        return 'No connection to the network can be established, please check your network settings';
      case 400:
        return 'errors.400';
      case 401:
        return 'errors.401';
      case 403:
        return 'errors.403';
      case 404:
        if (matched && matched.length === 1) {
          return 'errors.box-not-found';
        }
        return 'errors.404';
      case 405:
        return 'errors.405';
      case 406:
        return 'errors.406';
      case 408:
        return 'errors.408';
      case 412:
        return message;
      case 422:
        if (errors) {
          const err = [] as any;
          Object.entries(errors).forEach(([key, value]) => {
            err.push(`${key}: ${value}`);
          });
          let showErrors = '';
          err.forEach((item: any) => {
            showErrors += `${item} `;
          });
          return showErrors;
        }
        return message;
      case 500:
        return 'errors.500';
      case 502:
        return 'errors.502';
      case 503:
        return 'errors.503';
      default:
        return `${status}`;
    }
  },
};

export default ErrorHandler;
