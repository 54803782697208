










import Vue from 'vue';
import { mapMutations } from 'vuex';
import localStorage from '@/services/localStorage';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import UpdateAlert from '@/components/ui/UpdateAlert.vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    ...mapMutations('globals', ['SET_TOKEN']),
  },
  created() {
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('locale') || 'en';
    this.$i18n.locale = lang;
    if (token) {
      this.SET_TOKEN(token);
    }
  },

  components: {
    BaseAlert,
    UpdateAlert,
  },
});
