import Axios, { AxiosRequestConfig } from 'axios';
import AxiosInterceptors from './axiosInterceptores';

const axiosConfig: AxiosRequestConfig = {};

const baseURL: string = process.env.VUE_APP_BASE_URL;

axiosConfig.baseURL = baseURL;

const AxiosInstance = Axios.create(axiosConfig);

const interceptors = new AxiosInterceptors();

interceptors.setAxiosInterceptor(AxiosInstance);

export default AxiosInstance;
