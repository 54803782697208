import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import box from '@/assets/icons/box.svg';
import shoppingBag from '@/assets/icons/shopping-bag.svg';
import arrowLeft from '@/assets/icons/arrowLeft.svg';
import arrowRight from '@/assets/icons/arrowRight.svg';
import bookmark from '@/assets/icons/bookmark.svg';
import minus from '@/assets/icons/minus.svg';
import smile from '@/assets/icons/smile.svg';
import trash from '@/assets/icons/Trash.svg';
import paperclip from '@/assets/icons/paperclip.svg';
import cancel from '@/assets/icons/cancel.svg';
import globe from '@/assets/icons/globe.svg';
import user from '@/assets/icons/user.svg';
import settingsIcon from '@/assets/icons/settingsicon.svg';
import eye from '@/assets/icons/eye.svg';
import eyeOff from '@/assets/icons/eye-off.svg';
import IconComponent from '@/components/IconComponent.vue';

Vue.use(Vuetify);

const fai = {
  box: {
    component: IconComponent,
    props: {
      path: box,
    },
  },
  shoppingBag: {
    component: IconComponent,
    props: {
      path: shoppingBag,
    },
  },
  arrowLeft: {
    component: IconComponent,
    props: {
      path: arrowLeft,
    },
  },
  arrowRight: {
    component: IconComponent,
    props: {
      path: arrowRight,
    },
  },
  bookmark: {
    component: IconComponent,
    props: {
      path: bookmark,
    },
  },
  minus: {
    component: IconComponent,
    props: {
      path: minus,
    },
  },
  smile: {
    component: IconComponent,
    props: {
      path: smile,
    },
  },
  trash: {
    component: IconComponent,
    props: {
      path: trash,
    },
  },
  paperclip: {
    component: IconComponent,
    props: {
      path: paperclip,
    },
  },
  clear: {
    component: IconComponent,
    props: {
      path: cancel,
    },
  },
  globe: {
    component: IconComponent,
    props: {
      path: globe,
    },
  },
  user: {
    component: IconComponent,
    props: {
      path: user,
    },
  },
  settingsIcon: {
    component: IconComponent,
    props: {
      path: settingsIcon,
    },
  },
  eye: {
    component: IconComponent,
    props: {
      path: eye,
    },
  },
  eyeOff: {
    component: IconComponent,
    props: {
      path: eyeOff,
    },
  },
};

const light = {
  primary: {
    base: '#81BCF7',
    lighten1: '#99C9F9',
    lighten2: '#B1D6FA',
    lighten5: '#EDF5FE',
  },
  secondary: '#CCD2A6',
  error: '#D35400',
  warning: '#E67E22',
};

export default new Vuetify({
  icons: {
    values: fai,
  },
  theme: {
    themes: {
      light,
    },
    options: {
      customProperties: true,
    },
  },
});
