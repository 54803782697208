import Vue from 'vue';
import { mutationTree } from 'typed-vuex';
import initialState from './state';
import { IBoxInventoryTemplateItem } from '@/services/Repositories/boxInventoryTemplate/types';

const mutations = mutationTree(initialState, {
  SET_NEWINVENTORYTEMPLATE(state, payload: boolean) {
    state.newInventoryTemplate = payload;
  },
  SET_DESCRIPTION(state, payload: string) {
    state.description = payload;
  },
  SET_ITEMS(state, payload: Array<IBoxInventoryTemplateItem>) {
    payload.forEach((item) => {
      state.items.push(item);
    });
  },
  CLEAR_STATES(state): void {
    state.newInventoryTemplate = true;
    state.description = '';
    state.items = [];
  },
});

export default mutations;
