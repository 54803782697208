/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { IRootState } from '@/store/types';
import globals from './modules/globals';
import orders from './modules/orders';
import reboxes from './modules/reboxes';
import lastEdited from './modules/lastEdited';
import boxes from './modules/boxes';
import configuration from './modules/configuration';

Vue.use(Vuex);

const initialModules = {
  globals,
  orders,
  reboxes,
  lastEdited,
  boxes,
  configuration,
};

const storePattern = {
  modules: { ...initialModules },
};

const store: Store<IRootState> = new Vuex.Store({
  ...storePattern,
});

export default store;
