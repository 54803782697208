// FODLER FOR TYPES
import { Process } from '@/utils/availableProcesses';

// eslint-disable-next-line no-shadow
export enum EnumsActions {
  noAction,
  processes,
  goBack,
  close,
  closeDialog,
}

// eslint-disable-next-line no-shadow
export enum EnumsIcons {
  cancel = 'cancel',
  processes = 'grid',
  back = 'leftChevron',
  noImage = '',
}

// eslint-disable-next-line no-shadow
export enum EnumsSecondaryIcons {
  noIcon = '',
  search = 'SearchIcon',
}

// eslint-disable-next-line no-shadow
export enum EnumFilterDrawerIcons {
  noIcon = '',
  filter = 'FilterIcon',
  settings = 'SettingsIcon',
}

export interface IStateGlobals {
  title: string;
  loader: boolean;
  alertTitle: string;
  alertText: string;
  alertTextDynamic: string; // field helps passing dynamic data from error responses
  alertDialogIsOpen: boolean;
  alertTwoButtons: boolean;
  token: string;
  processes: Array<Process>;
  icon: EnumsIcons;
  secondaryIcon: EnumsSecondaryIcons;
  filterDrawerIcon: EnumFilterDrawerIcons;
  filterDrawer: boolean;
  // settingsDrawerIcon: EnumSettingsDrawerIcons;
  searchBarInput: string;
  searchBy: string;
  previousPage: {
    name: string;
    params?: any;
  };
  action: EnumsActions;
  previousMainPage: string;
  userEmail: string | null;
  boxShippingData: [];
  params: {
    page?: number;
  };
  file: File | null;
  boxStates: [
    {
      stateIsTrue: boolean,
    },
    {
      stateIsTrue: boolean,
    },
  ],
  isBoxRegistrationComponent: boolean,
  registeredBoxes: string[],
  addProviderError: boolean,
}
