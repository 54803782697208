import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      refreshing: false,
      registration: null as any,
      updateExists: false,
    };
  },

  methods: {
    updateAvailable(event: any) {
      this.registration = event.detail;
      this.updateExists = true;
      this.refreshApp();
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) {
        return;
      }
      this.refreshing = true;
      window.location.reload();
    });
  },
});
