import { mutationTree } from 'typed-vuex';
import Vue from 'vue';
import initialState from './state';
import { IRebox } from './types';

const mutations = mutationTree(initialState, {
  ADD_REBOX(state, rebox: IRebox) {
    state.reboxes.push(rebox);
  },
  UPDATE_REBOX(state, rebox: IRebox) {
    const index = state.reboxes.findIndex(({ reboxNr }) => reboxNr === rebox.reboxNr);
    Vue.set(state.reboxes, index, rebox);
  },
  DELETE_REBOX(state, reboxId: string) {
    const reboxes = state.reboxes.filter(({ reboxNr }) => reboxNr !== reboxId);
    state.reboxes = reboxes;
  },
  PRINTED_LABEL(state, reboxId: string) {
    const index = state.reboxes.findIndex(({ reboxNr }) => reboxNr === reboxId);
    const rebox = state.reboxes.find(({ reboxNr }) => reboxNr === reboxId);
    Vue.set(state.reboxes, index, { ...rebox, printed: true });
  },
  ADD_LABEL(state, { label, reboxId }: { label: string; reboxId: string }) {
    const index = state.reboxes.findIndex(({ reboxNr }) => reboxNr === reboxId);
    const rebox = state.reboxes.find(({ reboxNr }) => reboxNr === reboxId);
    Vue.set(state.reboxes, index, { ...rebox, label });
  },
  CLEAR_REBOXES(state) {
    state.reboxes = [];
  },
});

export default mutations;
