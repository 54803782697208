/* eslint-disable no-shadow */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './registerServiceWorker';
import '@/plugins/veeValidateConfig';
import HeaderService from '@/services/injectedServices/HeaderService';

Vue.config.productionTip = false;

Vue.prototype.$headerService = new HeaderService();

declare module 'vue/types/vue' {
  interface Vue {
    $headerService: HeaderService;
  }
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
