<template>
  <div>
    <v-img :src="path" class="svg"></v-img>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.svg {
  fill: currentColor;
}
</style>
